import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'mpp-europa-legal-consents',
  templateUrl: './europa-legal-consents.component.html',
  styleUrls: ['./europa-legal-consents.component.scss'],
})
export class EuropaLegalConsentsComponent {
  @Input() form: UntypedFormGroup;
  @Input() formSubmitted: boolean;
}
