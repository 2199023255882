<div class="js-acc-panel">
  <form [formGroup]="form" (ngSubmit)="save()" class="c-login-form">
    <div class="c-acc-panel c-acc-panel--edit">
      <div class="o-layout">
        @if (!isJapanese) {
          <ng-template [ngTemplateOutlet]="firstNameInput"></ng-template>
          <ng-template [ngTemplateOutlet]="lastNameInput"></ng-template>
        }
        @if (isJapanese) {
          <ng-template [ngTemplateOutlet]="lastNameInput"></ng-template>
          <ng-template [ngTemplateOutlet]="firstNameInput"></ng-template>
        }

        <ng-template #lastNameInput>
          <!-- #region Last Name -->
          <div class="o-layout__item u-1/2@tablet">
            <div class="c-form-field">
              <label class="c-form-field__label" for="lastName">
                {{"MY-ACCOUNT/ABOUT-ME.LastName" | translate}}
              </label>
              <div class="c-form-field__input c-input--validation"
                [attr.data-form-field-status]="getDataFormFieldStatus('lastName')">

                <input type="text" id="lastName" class="c-input c-input--text" formControlName="lastName" [maxlength]="nameMaxLength" />
              </div>
              @if ((form.get('lastName').errors?.required || form.get('lastName').errors?.pattern) && form.get('lastName').touched) {
                <div
                class="c-form-field__error" role="alert" aria-live="assertive">{{"ERRORS.Required" | translate}}</div>
              }
            </div>
          </div>
          <!-- #endregion Last Name -->
        </ng-template>

        <ng-template #firstNameInput>
          <!-- #region First Name -->
          <div class="o-layout__item u-1/2@tablet">
            <div class="c-form-field">
              <label class="c-form-field__label" for="firstName">
                {{"MY-ACCOUNT/ABOUT-ME.FirstName" | translate}}
              </label>
              <div class="c-form-field__input c-input--validation"
                [attr.data-form-field-status]="getDataFormFieldStatus('firstName')">
                <input type="text" id="firstName" class="c-input c-input--text" formControlName="firstName" [maxlength]="nameMaxLength" />
              </div>
              @if ((form.get('firstName').errors?.required || form.get('firstName').errors?.pattern) && form.get('firstName').touched) {
                <div
                class="c-form-field__error" role="alert" aria-live="assertive">{{"ERRORS.Required" | translate}}</div>
              }
            </div>
          </div>
          <!-- #endregion First Name -->
        </ng-template>

        @if (isJapanese) {
          <!-- #Furigana Family Name -->
          <div class="o-layout__item u-1/2@tablet">
            <div class="c-form-field">
              <label class="c-form-field__label" for="furiganaFamilyName">
                {{"MY-ACCOUNT/ABOUT-ME.FuriganaFamilyName" | translate}}
              </label>
              <div class="c-form-field__input c-input--validation"
                [attr.data-form-field-status]="getDataFormFieldStatus('furiganaFamilyName')">
                <input type="text" id="furiganaFamilyName" class="c-input c-input--text" formControlName="furiganaFamilyName" [maxlength]="nameMaxLength" />
              </div>
              @if ((form.get('furiganaFamilyName').errors?.required || form.get('furiganaFamilyName').errors?.pattern) && form.get('furiganaFamilyName').touched) {
                <div
                class="c-form-field__error" role="alert" aria-live="assertive">{{"ERRORS.Required" | translate}}</div>
              }
            </div>
          </div>
          <!-- #end Furigana Family Name -->
          <!-- #Furigana Given Name -->
          <div class="o-layout__item u-1/2@tablet">
            <div class="c-form-field">
              <label class="c-form-field__label" for="furiganaGivenName">
                {{"MY-ACCOUNT/ABOUT-ME.FuriganaGivenName" | translate}}
              </label>
              <div class="c-form-field__input c-input--validation"
                [attr.data-form-field-status]="getDataFormFieldStatus('furiganaGivenName')">
                <input type="text" id="furiganaGivenName" class="c-input c-input--text" formControlName="furiganaGivenName" [maxlength]="nameMaxLength" />
              </div>
              @if ((form.get('furiganaGivenName').errors?.required || form.get('furiganaGivenName').errors?.pattern) && form.get('furiganaGivenName').touched) {
                <div
                class="c-form-field__error" role="alert" aria-live="assertive">{{"ERRORS.Required" | translate}}</div>
              }
            </div>
          </div>
          <!-- #end Furigana Given Name -->
        }
      </div>

      <mpp-add-date-of-birth [minimumAge]="minimumAge" [form]="form.get('birthday')"></mpp-add-date-of-birth>

      <!-- #region Gender -->
      <div class="c-form-field  u-margin-bottom-none">
        <label class="c-form-field__label">
          {{"MY-ACCOUNT/ABOUT-ME.Gender" | translate}}
        </label>
        <div class="c-form-field__input c-input--validation" data-form-field="signup-gender"
          [attr.data-form-field-status]="getDataFormFieldStatus('gender')">
          <div class="o-inline-group o-inline-group--top o-inline-group--block-at-mobile">
            @for (genderId of getGenderIds(); track genderId) {
              <div class="c-input c-input--radio align-top u-1/3@tablet">
                <input type="radio" formControlName="gender" [id]="genderId" [value]="genderId" /><label
              [for]="genderId">{{ getGenderName(genderId) | translate }}</label>
            </div>
          }
        </div>
      </div>
      @if (form.get('gender').errors?.required && form.get('gender').touched) {
        <div class="c-form-field__error is-pos-bottom is-list-field">
        {{"ERRORS.Required" | translate}}</div>
      }
    </div>
    <!-- #endregion Gender -->

    <!-- #region Time Zone -->
    <div class="c-form-field">
      <label class="c-form-field__label" for="timeZoneList">{{"REGISTRATION-FORM.TimeZone" | translate}}</label>

      <div class="c-form-field__input c-input--validation"
        [attr.data-form-field-status]="getDataFormFieldStatus('timezoneId')">
        <div class="c-input c-input--select">
          <select id="timeZoneList" formControlName="timezoneId">
            <option value=''>{{"REGISTRATION-FORM.ChooseATimeZone" | translate}}</option>
            @for (timeZone of allTimezones; track timeZone) {
              <option [value]="timeZone.id">{{ timeZone.name | translate }}</option>
            }
          </select>
        </div>

        @if ((form.get('timezoneId').errors?.required || form.get('timezoneId').errors?.pattern) && form.get('timezoneId')?.touched) {
          <div
          class="c-form-field__error" role="alert" aria-live="assertive">{{"ERRORS.Required" | translate}}</div>
        }

        <div class="c-form-field__hint">
          {{"REGISTRATION-FORM.TimeZoneTip" | translate}}
        </div>
      </div>
    </div>
    <!-- #endregion Time Zone -->

    <div class="c-form-buttons  [ o-layout o-layout--auto ]">
      <div class="o-layout__item">
        <button type="submit" class="c-btn c-btn--primary" [disabled]="form.invalid">{{ 'MY-ACCOUNT/ABOUT-ME.Save' | translate}}</button>
      </div>
      <div class="o-layout__item">
        <a class="c-btn c-btn--tertiary" (click)="cancel()">{{ 'MY-ACCOUNT/ABOUT-ME.Cancel' | translate}}</a>
      </div>
    </div>
  </div>
</form>
</div>
