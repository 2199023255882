<mpp-svg-sprite></mpp-svg-sprite>

@if (layout === 'country-selection') {
  <mpp-header-no-nav></mpp-header-no-nav>
  <main id="main" class="o-flexbody__page">
    <router-outlet></router-outlet>
  </main>
  <mpp-country-selection-footer></mpp-country-selection-footer>
}

@if (layout === 'accept-policies') {
  <mpp-header-no-nav></mpp-header-no-nav>
  <main id="main" class="o-flexbody__page">
    <router-outlet></router-outlet>
  </main>
}

@if (layout === 'accept-policies/policies') {
  <mpp-header-no-nav></mpp-header-no-nav>
  <main id="main" class="o-flexbody__page">
    <div class="u-margin-bottom-large [ u-3/4@desktop u-push-1/8@desktop ] [ u-8/10@wide u-push-1/10@wide ] [ u-10/12@wide u-push-1/12@wide ]">
      <a [routerLink]="['/accept-policies']" class="c-btn c-btn--text has-icon-left is-back-btn">
        <svg class="c-svg c-svg--chevron-left-24" aria-hidden="true">
          <use xlink:href="#c-svg--chevron-left-24"></use>
        </svg>
        <span>{{ 'ACCEPT-POLICIES.Back' | translate}}</span>
      </a>
    </div>
    <router-outlet></router-outlet>
  </main>
}

@if (layout === 'only-app-country') {
  <section class="o-section o-section--full-page-back-btn">
    <a [routerLink]="['/country-selection']" class="c-btn c-btn--text has-icon-left is-back-btn">
      <svg class="c-svg c-svg--chevron-left-24" aria-hidden="true">
        <use xlink:href="#c-svg--chevron-left-24"></use>
      </svg>
    </a>
  </section>
  <div class="o-flex-aligner" style="flex-grow: 1">
    <div class="o-flex-aligner__item">
      <mpp-header-no-nav></mpp-header-no-nav>
      <main id="main">
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>
  <mpp-footer></mpp-footer>
}

@if (layout === 'default') {
  <mpp-alert style="width: auto; height: auto; position: sticky; top: 0; z-index: 99998"></mpp-alert>
  <mpp-header></mpp-header>
  <main id="main" class="o-flexbody__page">
    <router-outlet></router-outlet>
  </main>
  <mpp-footer></mpp-footer>
}

<!-- OneTrust Cookies Settings button start -->
@if (showOneTrustFooter) {
  <button
    id="ot-sdk-btn"
    class="ot-sdk-show-settings"
    [ngStyle]="{display: layout === 'default' ? '' : 'none'}"
  style="background-color:#1788c3; color: #ffffff; border-color: #ffffff; border-width: 1px 0 0 0; cursor:pointer;"></button>
}
<!-- OneTrust Cookies Settings button end -->
