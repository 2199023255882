export function getFirebaseConfig(country: string): { [key: string]: string | boolean } {
  switch (country) {
    case 'CA': // Canada
    case 'AR': // LATAM
    case 'BR':
    case 'CL':
    case 'CO':
    case 'MX':
    case 'JP': // APAC
    case 'TW':
    case 'TH':
    case 'US': // AMR
    case 'AU':
    case 'NZ':
    default:
      return {
        apiKey: 'AIzaSyDmgAAltMhhNAIU3_PtwNLGD3AcOroKMbk',
        authDomain: 'myair-dev.firebaseapp.com',
        databaseURL: 'https://myair-dev.firebaseio.com',
        projectId: 'myair-dev',
        storageBucket: 'myair-dev.appspot.com',
        messagingSenderId: '687537107651',
        appId: '1:687537107651:web:6e3951ab5fc011c8446876',
        measurementId: 'G-5NG0PCN2MZ',
      };
    case 'GB': // EU
    case 'IE':
    case 'DE':
    case 'FI':
    case 'CH':
    case 'NL':
    case 'SE':
    case 'NO':
    case 'PL':
    case 'ES':
    case 'DK':
    case 'BE':
    case 'FR':
    case 'PT':
    case 'AT':
    case 'CZ':
    case 'GR':
    case 'IT':
    case 'CY':
    case 'ZA':
    case 'IS':
    case 'MT':
      return {
        apiKey: 'AIzaSyCt1_hqXfdr68bOaYp6G1EjGg-pfY1YoZc',
        authDomain: 'myair-eu-dev.firebaseapp.com',
        databaseURL: 'https://myair-eu-dev.firebaseio.com',
        projectId: 'myair-eu-dev',
        messagingSenderId: '113193916109',
        storageBucket: 'myair-eu-dev.appspot.com',
        appId: '1:113193916109:web:03a892db8b384144877b84',
        measurementId: 'G-872L4Q6TM4',
      };
  }
}
