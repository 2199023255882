import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map, of } from 'rxjs';
import { MetadataService } from '../metadata/metadata.service';
import { CountryService } from '../country/country.service';

@Injectable({
  providedIn: 'root'
})
export class ApiMetadataGuard {
  constructor(private metadata: MetadataService, private countryService: CountryService, private router: Router) {}

  canActivateChild(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }
  canLoad(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | UrlTree {
    if (!this.countryService.getSelectedCountryId()) {
      this.router.navigate(['country-selection']);
      return of(false);
    }
    return this.metadata.updateMetadataFromApi().pipe(
      map((_) => {
        return of(true);
      })
    );
  }
}
