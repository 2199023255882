import { UntypedFormGroup } from '@angular/forms';
import { DayJSService } from 'src/app/services/dayJS/dayJS.service';
import { StatisticEvents } from 'src/app/modules/registration/data/statistic-events.enum';
import {
  NameDetails,
  PatientInfo,
  PatientInfoWithConsent,
} from 'src/app/modules/registration/services/models/patient-info.model';
import { HealthProfile } from 'src/app/modules/registration/services/models/health-profile.model';
import { Statistics } from 'src/app/modules/registration/services/models/statistics.model';
import { LanguageService } from 'src/app/services/language/language.service';
import { AnalyticsMode } from 'src/graphql/types/api.types';
import { environment } from 'src/environments/environment';
import { CountryService } from 'src/app/services/country/country.service';

export interface BaseRestPatientInfo {
  getPatientInfo(): PatientInfo;
  getHealthProfile(): HealthProfile;
}

export interface BaseRestPatientInfoWithProfile {
  getPatientInfoForConsents(): PatientInfoWithConsent;
  getHealthProfile(): HealthProfile;
}

export class RestPatientInfoWithConsents implements BaseRestPatientInfoWithProfile {
  private registrationForm: UntypedFormGroup;

  constructor(
    registrationForm: UntypedFormGroup,
    private countryService: CountryService
  ) {
    this.registrationForm = registrationForm;
  }

  public getPatientInfoForConsents(): PatientInfoWithConsent {
    // analyticsFormValue toggles when the registration form analytics checkbox is checked. Default is false for Europe, true for all other countries. 
    const analyticsFormValue: boolean = this.registrationForm.get('patientLegalConsents.analyticsMode').value;
    const allowAnonymous = environment.isEU ? AnalyticsMode.untracked : AnalyticsMode.anonymous;
    const analyticsModeByRegion = this.countryService.getSelectedCountryId() === 'US' ? AnalyticsMode.identified : AnalyticsMode.anonymous;
    const analyticsMode = analyticsFormValue ? analyticsModeByRegion : allowAnonymous;

       return new PatientInfoWithConsent(
    this.registrationForm.get('patientLegalConsents.keepMeInformed').value,
    this.registrationForm.get('patientLegalConsents.shareDetailsWithProviderOptIn').value,
    analyticsMode,
  );
}
  
  public getHealthProfile(): HealthProfile {
   return new HealthProfile(
      this.getStatistics(),
      this.registrationForm.get('patientHealthProfile.sleepTestType').value,
      this.registrationForm.get('patientHealthProfile.startTherapyGroup').value,
      // implementation postponed due to MWEB-714
      // this.registrationForm.get('patientHealthProfile.baselineSleepiness').value,
    );
  }

  private getStatistics(): Statistics[] {
    return [
      new Statistics(
        StatisticEvents.BaselineApneaHypopneaIndex,
        this.registrationForm.get('patientHealthProfile.AHIevents').value?.toString(), // value of AHI events
      ),
    ];
  }
}

export class RestPatientInfo implements BaseRestPatientInfo {
  private registrationForm: UntypedFormGroup;

  constructor(
    registrationForm: UntypedFormGroup,
    private dayJSService: DayJSService,
    private languageService: LanguageService
  ) {
    this.registrationForm = registrationForm;
  }

  public getPatientInfo(): PatientInfo {
    const patientCountry = this.registrationForm.get('patientPersonalInfo.country').value;
// analyticsFormValue toggles when the registration form analytics checkbox is checked. Default is false for Europe, true for all other countries. 
const analyticsFormValue: boolean = this.registrationForm.get('patientLegalConsents.analyticsMode').value;
const allowAnonymous = environment.isEU ? AnalyticsMode.untracked : AnalyticsMode.anonymous;
const analyticsModeByRegion = patientCountry === 'US' ? AnalyticsMode.identified : AnalyticsMode.anonymous;
const analyticsMode = analyticsFormValue ? analyticsModeByRegion : allowAnonymous;

return new PatientInfo(
  new NameDetails(
    (this.registrationForm.get('patientPersonalInfo.firstName').value as string).trim(),
    (this.registrationForm.get('patientPersonalInfo.lastName').value as string).trim(),
    (this.registrationForm.get('patientPersonalInfo.furiganaGivenName').value as string).trim(),
    (this.registrationForm.get('patientPersonalInfo.furiganaFamilyName')
      .value as string).trim(),
  ),
  (this.registrationForm.get('patientPersonalInfo.signUpGroup.email').value as string).trim(),
  this.getFormattedBirthDay(),
  patientCountry,
  this.languageService.getCurrent2LettersLanguage(),
  this.registrationForm.get('patientPersonalInfo.timeZone').value,
  this.registrationForm.get('patientPersonalInfo.gender').value,
  !!this.registrationForm.get('patientLegalConsents.keepMeInformed').value,
  !!this.registrationForm.get('patientLegalConsents.shareDetailsWithProviderOptIn').value,
  analyticsMode,
  !!this.registrationForm.get('patientLegalConsents.processHealthDataOptIn').value,
  !!this.registrationForm.get('patientLegalConsents.termsOfUseConsent').value,
    );
  }

  private getFormattedBirthDay(): string {
    const birthYear = this.registrationForm.get('patientPersonalInfo.birthday.year').value;
    const birthMonth = this.registrationForm.get('patientPersonalInfo.birthday.month').value;
    const birthDay = this.registrationForm.get('patientPersonalInfo.birthday.day').value;
    const date = `${this.dayJSService
      .dayJS(birthYear, 'YYYY', true)
      .format('YYYY')}-${this.dayJSService
      .dayJS(birthMonth, 'MMM')
      .format('MM')}-${birthDay.toString().padStart(2, '0')}T00:00:00`;
    return date;
  }
  
  public getHealthProfile(): HealthProfile {
   return new HealthProfile(
      this.getStatistics(),
      this.registrationForm.get('patientHealthProfile.sleepTestType').value,
      this.registrationForm.get('patientHealthProfile.startTherapyGroup').value,
      // implementation postponed due to MWEB-714
      // this.registrationForm.get('patientHealthProfile.baselineSleepiness').value,
    );
  }

  private getStatistics(): Statistics[] {
    return [
      new Statistics(
        StatisticEvents.BaselineApneaHypopneaIndex,
        this.registrationForm.get('patientHealthProfile.AHIevents').value?.toString(), // value of AHI events
      ),
    ];
  }
}