import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AuthService } from '@auth/auth.service';
import { BaseComponent } from '../base/component/base-component';
import { environment } from 'src/environments/environment';
import { UserRegistrationService } from 'src/app/modules/registration/services/user-registration.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'mpp-add-flow-device',
  templateUrl: './add-flow-device.component.html',
  styleUrls: ['./add-flow-device.component.scss'],
})
export class AddFlowDeviceComponent extends BaseComponent implements OnInit {
  public isEU = environment.isEU;
  public equipmentFormGroup: UntypedFormGroup;
  public errorWithDevice = false;
  public legallyAuthorized = new UntypedFormControl('', this.isEU ? Validators.requiredTrue : undefined);
  public deviceVerified = false;
  public deviceImagePath: string = null;
  public deviceText: string = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private spinnerService: SpinnerService,
    public authService: AuthService,
    private userRegistration: UserRegistrationService,
    private patientService: PatientService,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.equipmentFormGroup = this.formBuilder.group({
      device: this.formBuilder.group({
        deviceSerialNumber: [null, [Validators.required, Validators.minLength(11)]],
        deviceNumber: [null, [Validators.required, Validators.minLength(3)]],
      }),
    });
    // If the form group is modified, reset the API-based validation
    this.equipmentFormGroup.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(_ => {
        this.deviceVerified = false;
    });
  }

  async onCancel(): Promise<void> {
    const spinner = this.spinnerService.show();
    this.analyticsService.logLoggedOutEvent();
    await this.authService.logout();
    spinner.hide();
  }

  async onStartOver(): Promise<void> {
    this.deviceVerified = false;
    this.equipmentFormGroup.reset();
  }

  verifyDevice(): void {
    if (this.equipmentFormGroup.valid) {
      const spinner = this.spinnerService.show();
      this.userRegistration
        .validateDevice(this.equipmentFormGroup.get('device.deviceSerialNumber').value,this.equipmentFormGroup.get('device.deviceNumber').value,
        ).pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (!result.result.success) {
            spinner.hide();
            const errorCode = result.result.errorCode as string;
            this.equipmentFormGroup.get('device').setErrors({ [errorCode]: true });
          } else {
            spinner.hide();
            this.deviceVerified = true;
          }})
    }
  }

  onSubmitDevice(): void {
    if (this.equipmentFormGroup.valid) {
      const spinner = this.spinnerService.show();
      this.patientService
        .addDevice({
          deviceNumber: this.equipmentFormGroup.get('device.deviceNumber').value,
          serialNumber: this.equipmentFormGroup.get('device.deviceSerialNumber').value,
        }).pipe(takeUntil(this.unsubscribe$))
        .subscribe(([error]) => {
          if (error) {
            const errorCode = error.errorInfo?.errorCode as string;
            this.equipmentFormGroup.get('device').setErrors({ [errorCode]: true });
          } else {
            this.analyticsService.logCompletedDeviceRegistrationEvent();
            this.router.navigate(['/dashboard']);
          }
          spinner.hide();
      });
    }
  }

  onVerifyDevice(): void {
    if (this.equipmentFormGroup.valid) {
      const spinner = this.spinnerService.show();
      this.userRegistration
        .validateDevice(this.equipmentFormGroup.get('device.deviceSerialNumber').value,this.equipmentFormGroup.get('device.deviceNumber').value)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response) => {
          if (response?.result?.errorCode) {
            this.equipmentFormGroup.get('device').setErrors({ [response?.result?.errorCode]: true });
          } else {
            this.deviceImagePath = environment.endpoints.staticMyair + '/' + (response.entity as any).imagePath;
            this.deviceText = (response.entity as any).localizedName;
            this.deviceVerified = true;
          }
          spinner.hide();
      });
    }
  }
}
