import { getCountryCode, getSupportedCountries } from '../js/countryCodeUtil';
import { getFirebaseConfig } from '../js/firebaseDev';
import versionData from 'cacheVersion.json';

export const environment = {
  production: false,
  isEU: true,
  okta: {
    clientId: '0oa3hn6jyenZfg6x50x7',
    issuer: 'https://resmed-uat-eu.oktapreview.com/oauth2/aus3hna6kqvzGBUvc0x7',
    redirectUri:
      typeof window !== 'undefined' && window.location.host.includes('localhost')
        ? 'http://localhost:4200'
        : 'https://myair-web-dev.rmdeu.live',
    scopes: ['openid', 'profile', 'email'],
    baseUrl: 'https://resmed-uat-eu.oktapreview.com',
  },
  endpoints: {
    staticMyair: 'https://static.myair-dev.rmdeu.live',
    localMetadataMyAir: `assets/${versionData.jsonVersion}/config/web-config.json`,
  },
  app_info: {
    app_version: '2.0.0',
    app_name: 'myAir EU',
  },
  firebase: getFirebaseConfig(getCountryCode()),
  supportedCountries: getSupportedCountries(),
  myAirLink: {
    EU: 'https://myair-web-dev.rmdeu.live',
    NonEU: 'https://myair-web-dev.dht.live',
  },
  appOnlyCountries: ['KR', 'SG', 'IN', 'HK', 'MY', 'PH'],
  oneTrustIdEU: 'f2a7e7b8-4e77-46d2-a8a9-ea9cb8e4c98a',
  oneTrustIdAMR: 'e2c29adf-4bb6-466a-aa5c-30e4291f132c'
};
