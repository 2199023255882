<header id="top" class="page-head" [class.is-logged-in]="isAuthenticated" role="banner">
  <button #skipBtn class="c-btn c-btn--secondary c-btn--skip-to-content" (click)="scrollToMainContent(skipBtn)">{{'HEADER.SkipToMainContent' | translate}}</button>
  @if (isAuthenticationSet && !isAuthenticated) {
    <mpp-language-menu></mpp-language-menu>
  }
  <div class="o-wrapper">
    <nav class="site-nav site-nav--main" [ngClass]="{'show-mobile-nav': isMenuOpen}">
      <div class="site-nav__branding">
        <mpp-res-med-label></mpp-res-med-label>
        <mpp-my-air-label></mpp-my-air-label>
      </div>

      @if (!isNotSupportedCountry) {
        <a (click)="toggleMenu()" class="site-nav__open"><span aria-hidden="true"></span><span aria-hidden="true"></span><span aria-hidden="true"></span></a>
      }

      @if (isAuthenticationSet && !isAuthenticated && !isNotSupportedCountry) {
        <ul class="site-nav__list">
          <li class="site-nav__item  site-nav__item--close">
            <a (click)="toggleMenu()" class="site-nav__link site-nav__close"><svg class="c-svg c-svg--close-24"><use xlink:href="#c-svg--close-24"></use></svg></a>
          </li>
          <li class="site-nav__item"
              routerLinkActive="is-active"
              [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              class="site-nav__link"
              (click)="toggleMenu()"
              [routerLink]="['/']"
              ><span>{{ 'HEADER.HomeLabel' | translate }}</span>
            </a>
          </li>
          <li class="site-nav__item"
              routerLinkActive="is-active"
              [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              class="site-nav__link"
              (click)="toggleMenu()"
              [routerLink]="['/account-registration']"
            ><span>{{ 'HEADER.CreateAccount' | translate }}</span>
            </a>
          </li>
          <li class="site-nav__item"
              routerLinkActive="is-active"
              [routerLinkActiveOptions]="{ exact: false }"
          >
            <a
              class="site-nav__link"
              (click)="toggleMenu()"
              [routerLink]="['/support']"
              ><span>{{ 'HEADER.Support' | translate }}</span>
            </a>
          </li>
        </ul>
      }
      @if (user && isAuthenticated && !isNotSupportedCountry) {
        <ul class="site-nav__list">
          <li class="site-nav__item  site-nav__item--close">
            <a (click)="toggleMenu()" class="site-nav__link site-nav__close"><svg class="c-svg c-svg--close-24"><use xlink:href="#c-svg--close-24"></use></svg></a>
          </li>
          <li class="site-nav__item site-nav__item--mobile-username">
            <div class="site-nav__link"><span>{{ 'HEADER.Hello' | translate: {name: user.FullName} }}</span></div>
          </li>
          <li class="site-nav__item"
              routerLinkActive="is-active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
            <a
              class="site-nav__link"
              (click)="toggleMenu()"
              [routerLink]="['/dashboard']"
            ><span>{{ 'HEADER.Dashboard' | translate }}</span>
            </a>
          </li>
          <li class="site-nav__item"
              routerLinkActive="is-active"
              [routerLinkActiveOptions]="{ exact: false }"
          >
            <a
              class="site-nav__link"
              (click)="toggleMenu()"
              [routerLink]="['/history']"
            ><span>{{ 'HEADER.History' | translate }}</span>
            </a>
          </li>
          <li class="site-nav__item"
              routerLinkActive="is-active"
              [routerLinkActiveOptions]="{ exact: true }"
          >
              <a
                class="site-nav__link"
                (click)="toggleMenu()"
                [routerLink]="['/library']"
              ><span>{{ 'HEADER.Library' | translate }}</span>
            </a>
          </li>
          <li class="site-nav__item">
            <a class="site-nav__link has-sub-menu js-no-click u-hide-below-tablet" title="{{ 'HEADER.Hello' | translate: {name:user.FullName} }}">
              <span><span class="user">{{ 'HEADER.Hello' | translate: {name:user.FullName} }}</span></span>
            </a>
            <ul class="site-nav__sub-menu is-last">
              <li class="site-nav__item"
                routerLinkActive="is-active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <a class="site-nav__sub-link" [routerLink]="['/my-account']" (click)="toggleMenu()"><span>{{ 'HEADER.MyAccount' | translate }}</span></a>
              </li>
              <li class="site-nav__item"
                routerLinkActive="is-active"
                [routerLinkActiveOptions]="{ exact: false }"
              >
                <a class="site-nav__sub-link" [routerLink]="['/support']" (click)="toggleMenu()"><span>{{ 'HEADER.Support' | translate }}</span></a>
              </li>
              <li class="site-nav__item"><a class="site-nav__sub-link" (click)="openSleepTherapyReportDialog()"><span>{{ 'HEADER.SleepTherapyReport' | translate }}</span></a></li>
              <li class="site-nav__item"><a class="site-nav__sub-link sign-out" (click)="logOut()"><span>{{ 'HEADER.SignOut' | translate }}</span></a></li>
            </ul>
          </li>
        </ul>
      }
    </nav>
  </div>
</header>
