<div class="c-acc-panel c-acc-panel--read">
  @if (!isJapanese) {
    <ng-template [ngTemplateOutlet]="firstNameInput"></ng-template>
    <ng-template [ngTemplateOutlet]="lastNameInput"></ng-template>
  }

  @if (isJapanese) {
    <ng-template [ngTemplateOutlet]="lastNameInput"></ng-template>
    <ng-template [ngTemplateOutlet]="firstNameInput"></ng-template>
  }

  <ng-template #lastNameInput>
    <div class="c-acc-panel__item">
      <div class="c-acc-panel__label">
        <span>{{ 'MY-ACCOUNT/ABOUT-ME.LastName' | translate}}</span>
      </div>
      <div class="c-acc-panel__value no-btn">
        <span>{{aboutMeDetails.lastName}}</span>
      </div>
    </div>
  </ng-template>

  <ng-template #firstNameInput>
    <div class="c-acc-panel__item">
      <div class="c-acc-panel__label">
        <span>{{ 'MY-ACCOUNT/ABOUT-ME.FirstName' | translate}}</span>
      </div>
      <div class="c-acc-panel__value no-btn">
        <span>{{aboutMeDetails.firstName}}</span>
      </div>
    </div>
  </ng-template>

  @if (isJapanese) {
    <div class="c-acc-panel__item">
      <div class="c-acc-panel__label">
        <span>{{ 'MY-ACCOUNT/ABOUT-ME.FuriganaFamilyName' | translate}}</span>
      </div>
      <div class="c-acc-panel__value no-btn">
        <span>{{ aboutMeDetails?.furiganaFamilyName | translate}}</span>
      </div>
    </div>
    <div class="c-acc-panel__item">
      <div class="c-acc-panel__label">
        <span>{{ 'MY-ACCOUNT/ABOUT-ME.FuriganaGivenName' | translate}}</span>
      </div>
      <div class="c-acc-panel__value no-btn">
        <span>{{ aboutMeDetails?.furiganaGivenName | translate}}</span>
      </div>
    </div>
  }

  <div class="c-acc-panel__item">
    <div class="c-acc-panel__label">
      <span>{{ 'MY-ACCOUNT/ABOUT-ME.DateOfBirth' | translate}}</span>
    </div>
    <div class="c-acc-panel__value no-btn">
      <span>{{ aboutMeDetails.dateOfBirth | fullDate }}</span>
    </div>
  </div>

  <div class="c-acc-panel__item">
    <div class="c-acc-panel__label">
      <span>{{ 'MY-ACCOUNT/ABOUT-ME.Gender' | translate}}</span>
    </div>
    <div class="c-acc-panel__value no-btn">
      <span>{{getGenderTranslation() | translate}}</span>
    </div>
  </div>

  <div class="c-acc-panel__item">
    <div class="c-acc-panel__label">
      <span>{{ 'REGISTRATION-FORM.TimeZone' | translate}}</span>
    </div>
    <div class="c-acc-panel__value no-btn">
      <span>{{ (timeZone ? timeZone.name : timeZoneId) | translate }}</span>
    </div>
  </div>
</div>
