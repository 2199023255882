import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AuthService } from '@auth/auth.service';
import { BaseComponent } from '../base/component/base-component';
import { environment } from 'src/environments/environment';
import { UserRegistrationService } from 'src/app/modules/registration/services/user-registration.service';

@Component({
  selector: 'mpp-device-registration',
  templateUrl: './device-registration.component.html',
  styleUrls: ['./device-registration.component.scss'],
})
export class DeviceRegistrationComponent extends BaseComponent implements OnInit {
  public isEU = environment.isEU;
  public equipmentFormGroup: UntypedFormGroup;
  public errorWithDevice = false;
  public legallyAuthorized = new UntypedFormControl('', this.isEU ? Validators.requiredTrue : undefined);
  public deviceVerified = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private spinnerService: SpinnerService,
    public authService: AuthService,
    private userRegistration: UserRegistrationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.equipmentFormGroup = this.formBuilder.group({
      device: this.formBuilder.group({
        deviceSerialNumber: [null, [Validators.required, Validators.minLength(11)]],
        deviceNumber: [null, [Validators.required, Validators.minLength(3)]],
      }),
    });
    // If the form group is modified, reset the API-based validation
    this.equipmentFormGroup.statusChanges.subscribe(_ => {
        this.deviceVerified = false;
    });
  }

  async onCancel(): Promise<void> {
    this.router.navigate(['/']);
  }

  async onStartOver(): Promise<void> {
    this.deviceVerified = false;
    this.equipmentFormGroup.reset();
  }

  verifyDevice(): void {
    if (this.equipmentFormGroup.valid) {
      const spinner = this.spinnerService.show();
      this.userRegistration
        .validateDevice(this.equipmentFormGroup.get('device.deviceSerialNumber').value,this.equipmentFormGroup.get('device.deviceNumber').value,
        )
        .subscribe((result) => {
          if (!result.result.success) {
            spinner.hide();
            const errorCode = result.result.errorCode as string;
            this.equipmentFormGroup.get('device').setErrors({ [errorCode]: true });
          } else {
            spinner.hide();
            this.deviceVerified = true;
          }})
    }
  }

  onSubmit(): void {
    if (this.equipmentFormGroup.valid) {
      this.userRegistration.deviceNumber = this.equipmentFormGroup.get('device.deviceNumber').value;
      this.userRegistration.serialNumber = this.equipmentFormGroup.get('device.deviceSerialNumber').value;
      this.router.navigate(['account-registration']);
    }
  }
}
