<div [formGroup]="form" class="u-padding-top-large">

  <h2 class="u-h4 u-margin-bottom-none">{{"REGISTRATION-FORM.EnhanceYourExperience" | translate}}</h2>
  <p class="c-text--optional">{{"REGISTRATION-FORM.EnhanceYourExperienceOptional" | translate}}</p>

  <fieldset class="c-form-field">
    <legend class="c-form-field__label u-margin-bottom-small" id="optInEnhanceExperience" [innerHTML]="'REGISTRATION-FORM.PrivacyNoticeInfo' | translate"></legend>

    <ul class="c-form-field__input c-input--validation" aria-labelledby="optInEnhanceExperience" role="group">
      <li class="c-input c-input--checkbox is-consent">
        <input id="shareInformation" formControlName="shareDetailsWithProviderOptIn" type="checkbox" />
        <label  class="c-form-field__label" for="shareInformation">
          <span>{{"REGISTRATION-FORM.EuropaShareMyInfoAgreement" | translate}}</span>
        </label>
      </li>
      <li class="c-input c-input--checkbox is-consent">
        <input id="allowAnalyticsCheckBox" formControlName="analyticsMode" type="checkbox" />
        <label class="c-form-field__label" for="allowAnalyticsCheckBox">
          {{"REGISTRATION-FORM.EuropaAllowToUseUserLevelAnalytics" | translate}}
        </label>
      </li>
      <li class="c-input c-input--checkbox is-consent">
        <input id="marketingOptInCheckBox" formControlName="keepMeInformed" type="checkbox" />
        <label class="c-form-field__label" for="marketingOptInCheckBox">
          {{"REGISTRATION-FORM.EuropaKeepMeInformedOfNews" | translate}}
        </label>
      </li>
    </ul>
  </fieldset>

  <fieldset class="c-form-field">
    <legend class="c-form-field__label u-margin-bottom-small" id="acceptToC" [innerHTML]="'REGISTRATION-FORM.TermsOfUseText' | translate"></legend>
    <ul class="c-form-field__input c-input--validation" aria-labelledby="acceptToC" role="group"
      data-form-field="signup-accept-terms-eu"
      [attr.data-form-field-status]="(form.get('termsOfUseConsent').touched
                                                  && form.get('termsOfUseConsent').invalid)
                                                  || (form.get('termsOfUseConsent').invalid
                                                  && formSubmitted)
                                                  ? 'error' : 'incomplete'">

      <li class="c-input c-input--checkbox is-consent">
        <input id="acceptTermsCheckBox" formControlName="termsOfUseConsent" type="checkbox" />
        <label class="c-form-field__label" for="acceptTermsCheckBox">{{"REGISTRATION-FORM.EuropaConsentTermsOfUse" | translate}}</label>
      </li>
    </ul>
  </fieldset>


  <h2 class="u-h4 u-margin-bottom-none" [innerHTML]="'REGISTRATION-FORM.ConditionsOfService' | translate"></h2>
  <p class="u-margin-bottom-none" [innerHTML]="'REGISTRATION-FORM.ProcessesYourHealthData' | translate"></p>
  <p class="u-margin-bottom-small" [innerHTML]="'REGISTRATION-FORM.TakeAMomentToReadPrivacyNotice' | translate"></p>

  <fieldset class="c-form-field">
    <legend class="u-hidden" id="acceptPrivacy"><span class="u-screen-reader-text" [innerHTML]="'REGISTRATION-FORM.ConditionsOfService' | translate"></span></legend>
    <ul class="c-form-field__input c-input--validation" aria-labelledby="acceptPrivacy" role="group"
      data-form-field="signup-accept-privacy-eu"
      [attr.data-form-field-status]="(form.get('processHealthDataOptIn').touched
                                                  && form.get('processHealthDataOptIn').invalid)
                                                  || (form.get('processHealthDataOptIn').invalid
                                                  && formSubmitted)
                                                  ? 'error' : 'incomplete'">
      <li class="c-input c-input--checkbox is-consent">
        <input id="processHealthDataOptInCheckBox" formControlName="processHealthDataOptIn" type="checkbox" />
        <label class="c-form-field__label" for="processHealthDataOptInCheckBox">{{"REGISTRATION-FORM.ProcessMyHealthDataConsent" | translate}}</label>
      </li>
    </ul>
    @if (((form.get('termsOfUseConsent').invalid
      || form.get('processHealthDataOptIn').invalid))
      && formSubmitted) {
      <div
        class="c-form-field__error"
        role="alert"
      aria-live="assertive">{{"ERRORS.AcceptanceOfTermsOfUseIsRequired" | translate}}</div>
    }
  </fieldset>


  <p class="u-font-weight-medium">
    {{"REGISTRATION-FORM.WeAreExcited" | translate}}
  </p>
</div>