<section id="section1" class="o-section">
    <div class="o-wrapper">
      <div class="o-layout">
        <div class="o-layout__item  [ u-10/12@desktop u-push-1/12@desktop ]">
  
          <h1 class="c-heading-page">Mask selection</h1>
          <div class="mask-description">The following steps will help us determine what type of mask you currently own so we can better tailor your therapy.</div>
  
              <!-- <div class="c-form--has-validation  [ u-11/12@tablet u-7/12@desktop ]">
                <div class="c-alert c-alert--warning c-alert--small show-icon">
                  <div class="c-alert__body">{{ 'DEVICE-REGISTRATION.DontHaveMachineAssociated' | translate}}</div>
                </div>
              </div> -->

              <mpp-add-mask [maskFormGroup]="equipmentFormGroup.get('mask')"></mpp-add-mask>        
  
          <div class="c-form-buttons  [ o-layout o-layout--auto ]">
            <div class="o-layout__item">
              <button class="c-btn c-btn--primary" validationgroup="SaveEquipment"
                (click)="onSubmit()" [disabled]="equipmentFormGroup.invalid">{{ 'MY-ACCOUNT/MY-PRIVACY.Save' | translate}}</button>
            </div>
            <div class="o-layout__item">
              <a class="c-btn c-btn--tertiary" (click)="onCancel()">{{"REGISTRATION-FORM.Cancel" | translate}}</a>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </section>
  