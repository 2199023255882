/* eslint-disable max-classes-per-file */
import { GenderId } from 'src/app/modules/registration/data/gender.type';
import { AnalyticsMode } from 'src/graphql/types/api.types';

export class NameDetails {
  constructor(
    public givenName: string,
    public familyName: string,
    public furiganaGivenName: string,
    public furiganaFamilyName: string,
  ) {}
}

export class PatientInfo {
  public nameDetails: NameDetails;
  public email: string;
  public dateOfBirth: string;
  public isoCountryCode: string;
  public locale: string;
  public timeZoneId: string;
  public personGender: GenderId;
  public marketingOptIn: boolean;
  public shareDetailsWithProviderOptIn: boolean;
  public analyticsMode: AnalyticsMode;
  public processHealthDataOptIn: boolean;
  public acceptTerms: boolean;

  constructor(
    nameDetailsIn: NameDetails,
    emailIn: string,
    dateOfBirthIn: string,
    isoCountryCodeIn: string,
    localeIn: string,
    timeZoneIdIn: string,
    personGenderIn: GenderId,
    marketingOptInIn: boolean,
    shareDetailsWithProviderOptInIn: boolean,
    analyticsModeIn: AnalyticsMode,
    processHealthDataOptInIn: boolean,
    acceptTermsIn: boolean,
  ) {
    this.nameDetails = nameDetailsIn;
    this.email = emailIn;
    this.dateOfBirth = dateOfBirthIn;
    this.isoCountryCode = isoCountryCodeIn;
    this.locale = localeIn;
    this.timeZoneId = timeZoneIdIn;
    this.personGender = personGenderIn;
    this.marketingOptIn = marketingOptInIn;
    this.shareDetailsWithProviderOptIn = shareDetailsWithProviderOptInIn;
    this.analyticsMode = analyticsModeIn;
    this.processHealthDataOptIn = processHealthDataOptInIn;
    this.acceptTerms = acceptTermsIn;
  }
}

export class PatientInfoWithConsent {
  constructor(
    public marketingOptIn: boolean,
    public shareDetailsWithProviderOptIn: boolean,
    public analyticsMode: AnalyticsMode,
    // public processHealthDataOptIn: boolean,
   ) {}
}
