import { Injectable } from '@angular/core';
import { UrlTree, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CountryId } from 'src/app/types/country.type';
import { getCountryCode} from '../../../js/countryCodeUtil';
import { CountryService } from 'src/app/services/country/country.service';

@Injectable({
  providedIn: 'root',
})
export class USGuard  {
  constructor(
    private router: Router,
    private countryService: CountryService
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.canActivate(route);
  }
  canLoad(route: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.canActivate(route);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const countryFromIP = getCountryCode(true) as CountryId;
    const currentCountry = this.countryService.getSelectedCountryId();
    return countryFromIP === 'US' && currentCountry === 'US' ? true : this.router.createUrlTree(['country-selection'])
}
}