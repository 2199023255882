import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'mpp-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss'],
})
export class GetStartedComponent {
  constructor(public analytics: AnalyticsService, public router: Router) {}

  goToAccountRegistration(): void {
    this.analytics.logClickCreateAccountEvent();
    // Switch to this in v2
    // this.router.navigate(['/machine-verification']);
    this.router.navigate(['/account-registration']);
  }
}
