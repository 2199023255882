<section id="myAccountDetails" class="o-section">
  <div class="o-wrapper">
    <div class="o-layout">
      <div class="o-layout__item  [ u-push-1/12@desktop u-11/12@desktop ]">
        <h1 class="c-heading-main u-margin-bottom-large">{{ 'MY-ACCOUNT.MyAccount' | translate}}</h1>
        <div class="c-acc-panel_col">
          @if (hasPatientData) {
            <div>
              <mpp-account-details [email]="patient.patient.email"></mpp-account-details>
              <mpp-about-me [aboutMeDetails]="aboutMeDetails" [isJapanese]="isJapanese"></mpp-about-me>
              <mpp-my-equipment [device]="patient.fgDevices[0]" [mask]="patient.masks[0]"></mpp-my-equipment>
              <mpp-my-notifications [notificationPreferences]="notiificationPreferences"></mpp-my-notifications>
              <mpp-my-health-profile [healthProfile]="healthProfile"></mpp-my-health-profile>
              <mpp-my-privacy [privacySettings]="privacySettings" [email]="patient.patient.email" [showSmartCoachingOptOut]="showSmartCoachingOptOut"></mpp-my-privacy>
            </div>
          }
          <mpp-delete-account></mpp-delete-account>
        </div>
      </div>
    </div>
  </div>
</section>
